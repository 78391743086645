/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~bootstrap";

// Custom Variables
$blue: #23c3e7;
$green: #97d101;
$pink: #ff75a5;

// Fonts

@font-face {
  font-family: 'Borden';
  src: url("assets/fonts/Borden.ttf");
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url("assets/fonts/Montserrat-Bold_3.otf");
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: url("assets/fonts/Montserrat-SemiBold_2.otf");
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 200;
  src: url("assets/fonts/Montserrat-ExtraLight_1.otf");
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: url("assets/fonts/Montserrat-Medium_1.otf");
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 100;
  src: url("assets/fonts/Montserrat-Thin_1.otf");
}

@font-face {
  font-family: 'Myriad Pro';
  src: url("assets/fonts/MyriadPro-Regular.otf");
}

@font-face {
  font-family: 'DK Crayon Crumble';
  src: url("assets/fonts/editorFonts/DK-Crayon-Crumble.otf");
}

@font-face {
  font-family: 'A Arrange Signature';
  src: url("assets/fonts/editorFonts/aArrangeSignature.otf");
}

@font-face {
  font-family: 'Cheddar Gothic Stencil';
  src: url("assets/fonts/editorFonts/CheddarGothic-Stencil.otf");
}

@font-face {
  font-family: 'Free Pen';
  src: url("assets/fonts/editorFonts/Free-Pen.otf");
}

@font-face {
  font-family: 'Mastura';
  src: url("assets/fonts/editorFonts/Mastura.otf");
}

@font-face {
  font-family: 'Alegreya';
  src: url("assets/fonts/editorFonts/Alegreya-VariableFont_wght.ttf");
}

@font-face {
  font-family: 'AmaticSC';
  src: url("assets/fonts/editorFonts/AmaticSC-Regular.ttf");
}

@font-face {
  font-family: 'Arvo';
  src: url("assets/fonts/editorFonts/Arvo-Regular.otf");
}

@font-face {
  font-family: 'CoveredByYourGrace';
  src: url("assets/fonts/editorFonts/CoveredByYourGrace-Regular.ttf");
}

@font-face {
  font-family: 'Exo2';
  src: url("assets/fonts/editorFonts/Exo2-VariableFont_wght.ttf");
}

@font-face {
  font-family: 'FiraMono';
  src: url("assets/fonts/editorFonts/FiraMono-Regular.ttf");
}

@font-face {
  font-family: 'Inconsolata';
  src: url("assets/fonts/editorFonts/Inconsolata.otf");
}

@font-face {
  font-family: 'IndieFlower';
  src: url("assets/fonts/editorFonts/IndieFlower-Regular.ttf");
}

@font-face {
  font-family: 'JosefinSans';
  src: url("assets/fonts/editorFonts/JosefinSans-VariableFont_wght.ttf");
}

@font-face {
  font-family: 'LaBelleAurore';
  src: url("assets/fonts/editorFonts/LaBelleAurore-Regular.ttf");
}

@font-face {
  font-family: 'LibreBaskerville';
  src: url("assets/fonts/editorFonts/LibreBaskerville-Regular.ttf");
}

@font-face {
  font-family: 'OpenSans';
  src: url("assets/fonts/editorFonts/OpenSans-VariableFont_wdth,wght.ttf");
}

@font-face {
  font-family: 'Playball';
  src: url("assets/fonts/editorFonts/Playball-Regular.ttf");
}

@font-face {
  font-family: 'Quicksand';
  src: url("assets/fonts/editorFonts/Quicksand-VariableFont_wght.ttf");
}

@font-face {
  font-family: 'Sacramento';
  src: url("assets/fonts/editorFonts/Sacramento-Regular.ttf");
}

@font-face {
  font-family: 'SourceSerifPro';
  src: url("assets/fonts/editorFonts/SourceSerifPro-Regular.ttf");
}


@font-face {
  font-family: 'Chomsky';
  font-weight: normal;
  src: url("assets/fonts/editorFonts/font-testing/broken/Chomsky.otf");
}

@font-face {
  font-family: 'KJV1611';
  font-weight: normal;
  src: url("assets/fonts/editorFonts/font-testing/broken/KJV1611.otf");
}

@font-face {
  font-family: 'Grinched 2';
  font-weight: normal;
  src: url("assets/fonts/editorFonts/font-testing/comic/grinched-2-0.regular.otf");
}

@font-face {
  font-family: 'Planet Benson 2';
  font-weight: normal;
  src: url("assets/fonts/editorFonts/font-testing/comic/planet benson 2.otf");
}

@font-face {
  font-family: 'Air America';
  font-weight: normal;
  src: url("assets/fonts/editorFonts/font-testing/display/AirAmerica-Regular.otf");
}

@font-face {
  font-family: 'Archivo Black';
  font-weight: normal;
  src: url("assets/fonts/editorFonts/font-testing/display/ArchivoBlack.otf");
}

@font-face {
  font-family: 'Beth Ellen 2';
  font-weight: normal;
  src: url("assets/fonts/editorFonts/font-testing/hand/Beth-Ellen-2.0.otf");
}

@font-face {
  font-family: 'Caroni';
  font-weight: normal;
  src: url("assets/fonts/editorFonts/font-testing/hand/Caroni-Regular.otf");
}

@font-face {
  font-family: 'Daniel';
  font-weight: bold;
  src: url("assets/fonts/editorFonts/font-testing/hand/Daniel-Bold.otf");
}

@font-face {
  font-family: 'Daniel';
  font-weight: normal;
  src: url("assets/fonts/editorFonts/font-testing/hand/Daniel-Regular.otf");
}

@font-face {
  font-family: 'Fantasquesansmono';
  font-weight: bold;
  src: url("assets/fonts/editorFonts/font-testing/monospace/fantasquesansmono-bold.otf");
}

@font-face {
  font-family: 'Fantasquesansmono';
  font-weight: normal;
  src: url("assets/fonts/editorFonts/font-testing/monospace/fantasquesansmono-regular.otf");
}

@font-face {
  font-family: 'Fira Code';
  font-weight: bold;
  src: url("assets/fonts/editorFonts/font-testing/monospace/FiraCode-Bold.otf");
}

@font-face {
  font-family: 'Fira Code';
  font-weight: normal;
  src: url("assets/fonts/editorFonts/font-testing/monospace/FiraCode-Regular.otf");
}

@font-face {
  font-family: 'Source Code Pro';
  font-weight: bold;
  src: url("assets/fonts/editorFonts/font-testing/monospace/SourceCodePro-Bold.otf");
}

@font-face {
  font-family: 'Source Code Pro';
  font-weight: normal;
  src: url("assets/fonts/editorFonts/font-testing/monospace/SourceCodePro-Regular.otf");
}

@font-face {
  font-family: 'Alegreya Sans';
  font-weight: bold;
  src: url("assets/fonts/editorFonts/font-testing/sans-serif/AlegreyaSans-Bold.otf");
}

@font-face {
  font-family: 'Alegreya Sans';
  font-weight: normal;
  src: url("assets/fonts/editorFonts/font-testing/sans-serif/AlegreyaSans-Regular.otf");
}

@font-face {
  font-family: 'Bitter';
  font-weight: bold;
  src: url("assets/fonts/editorFonts/font-testing/sans-serif/Bitter-Bold.otf");
}

@font-face {
  font-family: 'Bitter';
  font-weight: normal;
  src: url("assets/fonts/editorFonts/font-testing/sans-serif/Bitter-Regular.otf");
}

@font-face {
  font-family: 'Merriweather';
  font-weight: bold;
  src: url("assets/fonts/editorFonts/font-testing/sans-serif/Merriweather-Bold.otf");
}

@font-face {
  font-family: 'Merriweather';
  font-weight: normal;
  src: url("assets/fonts/editorFonts/font-testing/sans-serif/Merriweather-Regular.otf");
}

@font-face {
  font-family: 'Allura';
  font-weight: normal;
  src: url("assets/fonts/editorFonts/font-testing/script/Allura-Regular.otf");
}

@font-face {
  font-family: 'Bilbo';
  font-weight: normal;
  src: url("assets/fonts/editorFonts/font-testing/script/Bilbo-Regular.otf");
}

@font-face {
  font-family: 'AlegreyaSC';
  font-weight: bold;
  src: url("assets/fonts/editorFonts/font-testing/serif/AlegreyaSC-Bold.otf");
}

@font-face {
  font-family: 'AlegreyaSC';
  font-weight: normal;
  src: url("assets/fonts/editorFonts/font-testing/serif/AlegreyaSC-Regular.otf");
}

@font-face {
  font-family: 'Aleo';
  font-weight: bold;
  src: url("assets/fonts/editorFonts/font-testing/serif/Aleo-Bold.otf");
}

@font-face {
  font-family: 'Aleo';
  font-weight: normal;
  src: url("assets/fonts/editorFonts/font-testing/serif/Aleo-Regular.otf");
}

@font-face {
  font-family: 'Crimson';
  font-weight: bold;
  src: url("assets/fonts/editorFonts/font-testing/serif/Crimson-Bold.otf");
}

@font-face {
  font-family: 'Crimson';
  font-weight: normal;
  src: url("assets/fonts/editorFonts/font-testing/serif/Crimson-Roman.otf");
}

@font-face {
  font-family: 'Lora';
  font-weight: bold;
  src: url("assets/fonts/editorFonts/font-testing/serif/Lora-Bold.ttf");
}

@font-face {
  font-family: 'Lora';
  font-weight: normal;
  src: url("assets/fonts/editorFonts/font-testing/serif/Lora-Regular.ttf");
}

.bold {
  font-weight: bold;
}

.bilbo {
  font-family: 'Bilbo';
}

.chomsky {
  font-family: 'Chomsky';
}

.kJV1611 {
  font-family: 'KJV1611';
}

.grinched2 {
  font-family: 'Grinched 2';
}

.planetBenson2 {
  font-family: 'Planet Benson 2';
}

.airAmerica {
  font-family: 'Air America';
}

.archivoBlack {
  font-family: 'Archivo Black';
}

.bethEllen2 {
  font-family: 'Beth Ellen 2';
}

.caroni {
  font-family: 'Caroni';
}

.daniel {
  font-family: 'Daniel';
}

.fantasquesansmono {
  font-family: 'Fantasquesansmono';
}

.firaCode {
  font-family: 'Fira Code';
}

.sourceCodePro {
  font-family: 'Source Code Pro';
}

.alegreyaSans {
  font-family: 'Alegreya Sans';
}

.bitter {
  font-family: 'Bitter';
}

.merriweather {
  font-family: 'Merriweather';
}

.allura {
  font-family: 'Allura';
}

.alegreyaSC {
  font-family: 'AlegreyaSC';
}

.aleo {
  font-family: 'Aleo';
}

.crimson {
  font-family: 'Crimson';
}

.lora {
  font-family: 'Lora';
}

.borden {
  font-family: 'Borden';
}

.montserrat {
  font-family: 'Montserrat';
}

.myriad {
  font-family: 'Myriad Pro';
}

.crayon {
  font-family: 'DK Crayon Crumble';
}

.Alegreya {
  font-family: 'Alegreya';
}

.AmaticSC {
  font-family: 'AmaticSC';
}

.Arvo {
  font-family: 'Arvo';
}

.CoveredByYourGrace {
  font-family: 'CoveredByYourGrace';
}

.Exo2 {
  font-family: 'Exo2';
}

.FiraMono {
  font-family: 'FiraMono';
}

.Inconsolata {
  font-family: 'Inconsolata';
}

.IndieFlower {
  font-family: 'IndieFlower';
}

.JosefinSans {
  font-family: 'JosefinSans';
}

.LaBelleAurore {
  font-family: 'LaBelleAurore';
}

.LibreBaskerville {
  font-family: 'LibreBaskerville';
}

.OpenSans {
  font-family: 'OpenSans';
}

.Playball {
  font-family: 'Playball';
}

.Quicksand {
  font-family: 'Quicksand';
}

.Sacramento {
  font-family: 'Sacramento';
}

.SourceSerifPro {
  font-family: 'SourceSerifPro';
}

.w-normal {
  font-weight: normal;
}

.w-bold {
  font-weight: bold;
}

.w-100 {
  font-weight: 100;
}

.w-200 {
  font-weight: 200;
}

.font-style-hidden {
  display: none !important;
}

a {
  color: $pink;
  font-family: 'Myriad Pro';
  font-weight: bold;
  text-decoration: none;

  &:hover, &:focus, &:active {
    &:not(.button) {
      color: darken($pink, 10%) !important;
    }
  }

  &.upper {
    text-transform: uppercase;
  }

  &.button {
    background-color: #ff75a5;
    color: white;
    padding: 0 0.5rem;
    border-radius: 12px;
    border: 1px solid black;

  }
}

button {
  background-color: $green;
  color: white;
  border-radius: 100px;
  width: 100%;
  border: 1px solid black;
  font-family: 'Borden';
  font-size: 58px;
  line-height: 50px;
  padding-bottom: 16px;

  &.w-auto {
    padding-left: 30px;
    padding-right: 30px;
  }

  &.back {
    background-color: $blue;
    font-size: 30px !important;
    line-height: 30px;
    position: absolute;
    top: 20px;
    left: 20px;
    width: auto;
    padding: 0 20px 5px;
  }

  &.profile {
    background-color: $blue;
    font-size: 30px;
    line-height: 30px;
    position: absolute;
    top: 20px;
    right: 20px;
    width: auto;
    padding: 0 20px 5px;
  }

  &.logout {
    background-color: $blue;
    font-size: 30px;
    line-height: 30px;
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: auto;
    padding: 0 20px 5px;
  }

  &.toPorttoolio {
    background-color: $blue;
    font-size: 30px;
    line-height: 30px;
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: auto;
    padding: 0 20px 5px;
  }

  &.delete {
    background-color: $pink;
  }

  &[disabled] {
    filter: grayscale(1);
  }
}

input[type="checkbox"] {
  background-color: $blue;
}

.disable-scroll {
  > .grid-wrapper {
    height: 100%;
  }
}

.swiper-wrapper {
  width: auto !important;

  ion-slide {
    width: 100% !important;
  }
}

.bg-welcome {
  background: url("/assets/imgs/backgrounds/App_Welcome_no_logo.jpg");
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.bg-login {
  background: url("/assets/imgs/backgrounds/App_Login_no_logo.jpg");
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.bg-new-open {
  background: url("/assets/imgs/backgrounds/new project open project 2_New-open.jpg");
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.bg-lantern {
  background: url("/assets/imgs/backgrounds/main screen2_Group Test.jpg");
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.bg-editor {
  background: url("/assets/imgs/backgrounds/site editor2_File.jpg");
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.bg-child-select {
  background: url("/assets/imgs/backgrounds/child-select.jpg");
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.bg-design-select {
  background: url("/assets/imgs/backgrounds/design-select.jpg");
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.bg-digital-folder {
  background: url("/assets/imgs/backgrounds/digital-folder-bg.jpg");
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.plt-desktop {
  .alert-radio-label {
    font-size: 26px;
  }

  .alert-button-inner {
    font-size: 26px;
  }

  .sc-ion-alert-md-h {
    --max-width: 500px;
    --min-width: 350px;
  }
}

.editor {
  .canvas-container {
    position: absolute !important;
    top: 0;
    left: 0;
  }
}

app-child-detail {
  overflow-y: auto !important;
}

button.alert-button {
  width: auto !important;
}


#canvas-wrapper-2,
#canvas-wrapper-3 {
  .canvas-container {
    position: relative !important;
    margin: 0 auto;
  }
}

.selectChildrenForPageDownload,
.selectChildrenForPageDelete {
  --height: 80vh;
  --width: 50vw;
}


ngx-file-drop {
  &.custom-height {
    .ngx-file-drop__drop-zone {
      height: 70px !important;

      .ngx-file-drop__content {
        height: 70px !important;
      }
    }
  }
}

ion-alert {
  &.vertical-buttons {
    .alert-button-group {
      flex-direction: row;
      justify-content: space-between;
      -webkit-justify-content: space-between;
    }
  }

  &.huge-width {
    --max-width: 80vw !important;
  }

  &.bigger-buttons {
    button {
      font-size: 20px !important;
    }
  }
}

.password-eye {
  background-image: url("/assets/icon/Preview/Preview (6).ico");
  background-position: center;
  background-size: contain;
  filter: saturate(0) brightness(0);
  position: absolute;
  height: 30px;
  width: 30px;
  right: 20px;
  top: calc(50% - 15px);
  cursor: pointer;
  &.show {

  }

  &.hide {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 2px;
      background-color: black;
      left: calc(50% - 1px);
      transform: rotate(45deg);
    }
  }
}


@media screen and (max-device-width: 800px) {
}

@media screen and (max-device-width: 1300px) {
  .hiddenAllHandheld {
    display: none !important;
  }
}
